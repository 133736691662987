import React, { useEffect } from 'react';
import styles from './ParcoursClient.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBicycle, faHandshake, faHome } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const ProprieteVelo = ({ formData, setFormData, setCurrentStep }) => {
  const { t } = useTranslation();

  const handleSelection = (propriete) => {
    setFormData((prevData) => ({
      ...prevData,
      proprieteVelo: propriete,
    }));
    setCurrentStep(5); // Passe à l'étape suivante
  };

  // Forcer le défilement en haut au chargement de la page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <h2 className={`text-center mb-4 ${styles.titleHeading}`}>
        {t('ParcourClient.questions4.purchase_type')}
      </h2>
      <div className="d-flex flex-column align-items-center">
        {/* Carte pour Propre */}
        <div
          className={`${styles.hoverCard} card text-center p-3 mb-3`}
          style={{ cursor: 'pointer', width: '100%', maxWidth: '600px', border: '1px solid #ddd' }}
          onClick={() => handleSelection('Propre')}
        >
          <div className={styles.cardBody}>
            <h5 className={styles.cardTitle}>
              {t('ParcourClient.option_purchase_type.mybike')}
            </h5>
          </div>
        </div>

        {/* Carte pour Leasing */}
        <div
          className={`${styles.hoverCard} card text-center p-3 mb-3`}
          style={{ cursor: 'pointer', width: '100%', maxWidth: '600px', border: '1px solid #ddd' }}
          onClick={() => handleSelection('Leasing')}
        >
          <div className={styles.cardBody}>
            <h5 className={styles.cardTitle}>
              {t('ParcourClient.option_purchase_type.leasingbike')}
            </h5>
          </div>
        </div>

        {/* Carte pour Location longue durée */}
        <div
          className={`${styles.hoverCard} card text-center p-3 mb-3`}
          style={{ cursor: 'pointer', width: '100%', maxWidth: '600px', border: '1px solid #ddd' }}
          onClick={() => handleSelection('Location longue durée')}
        >
          <div className={styles.cardBody}>
            <h5 className={styles.cardTitle}>
              {t('ParcourClient.option_purchase_type.rentalbike')}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProprieteVelo;
