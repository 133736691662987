import React from 'react';
import styles from './ProgressBar.module.css';

const ProgressBar = ({ currentStep, totalSteps }) => {
  const progress = (currentStep / totalSteps) * 100;

  return (
    <div className={styles.progressContainer}>
      <div className={styles.progress}>
        {/* Barre de progression */}
        <div
          className={styles.progressBar}
          style={{
            width: `${progress}%`,
          }}
          role="progressbar"
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {/* Icône vélo */}
          <div
            className={styles.bikeIcon}
            style={{
              left: `${progress}%`,
            }}
          >
            🚴
          </div>
        </div>
      </div>
      {/* Texte d'indication de l'étape */}
      <div className={styles.stepIndicator}>
        Étape {currentStep}/{totalSteps}
      </div>
    </div>
  );
};

export default ProgressBar;
