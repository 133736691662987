import React, { useState } from 'react'; // Import de React et useState
import { FaBicycle, FaShieldAlt, FaUser } from 'react-icons/fa'; // Import des icônes
import { useNavigate } from 'react-router-dom'; // Import de useNavigate
import { useTranslation } from 'react-i18next'; // Import de useTranslation
import { Card, Button, Row, Col, Modal, Spinner, Container } from 'react-bootstrap'; // Import des composants Bootstrap
import axiosInstance from '../../../API/axiosInstance'; // Import de axiosInstance
import styles from './ParcoursClient.module.css'; // Import des styles CSS


const Recapitulatif = ({ formData, vendorId: vendorIdProp, setCurrentStep }) => {
  const vendorId = formData.vendorId || vendorIdProp || 'defaultVendorId';
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [changes, setChanges] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [clientId, setClientId] = useState(null);

  const {
    bikeType,
    marque,
    modele,
    prixAchat,
    dateAchat,
    proprieteVelo,
    deplacementType,
    nom,
    prenom,
    pesel,
    email,
    telephone,
    adresse,
    ville,
    codePostal,
    selectedAssurance,
  } = formData;

  const getSelectedItems = () => {
    const selectedItems = [];
    if (bikeType) {
      const deplacementMapped =
        deplacementType.toLowerCase() === 'particuliers'
          ? 'particulier'
          : deplacementType.toLowerCase() === 'professionnels'
          ? 'professionnel'
          : 'les deux';
  
      selectedItems.push({
        type: 'velo',
        type_velo: bikeType,
        marque,
        modele,
        prix_achat: prixAchat,
        date_achat: `${dateAchat.year}-${dateAchat.month}-${dateAchat.day}`,
        propriete: proprieteVelo,
        deplacement: deplacementMapped,
      });
    }
    if (selectedAssurance) {
      selectedItems.push({
        type: 'assurance',
        id: selectedAssurance.id,
        prix: selectedAssurance.prix, // Prix explicite ajouté
      });
    }
    return selectedItems;
  };
  
  

  const validateForm = () => {
    if (!email || !nom || !prenom || !pesel || !adresse || !ville || !codePostal || !telephone) {
      setError('Tous les champs obligatoires doivent être remplis.');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
  
    setIsLoading(true);
    try {
      const selectedItems = getSelectedItems();
  
      const verifyResponse = await axiosInstance.post('/verifier-client', {
        email,
        nom,
        prenom,
        pesel,
        telephone,
        adresse,
        ville,
        code_postal: codePostal,
      });
  
      const { clientId: newClientId, changes: detectedChanges } = verifyResponse.data;
      setClientId(newClientId);
  
      if (detectedChanges.length > 0) {
        setChanges(detectedChanges);
        setShowPopup(true);
      } else {
        await ajouterClient(newClientId, selectedItems);
        await handleTransaction(newClientId, selectedItems);
      }
    } catch (error) {
      if (error.response?.status === 404) {
        // Si le client n'existe pas, créez-le directement
        console.log('Client non trouvé. Création en cours...');
        try {
          const response = await axiosInstance.post('/ajouter-client', {
            nom,
            prenom,
            pesel,
            email,
            telephone,
            adresse,
            ville,
            code_postal: codePostal,
            selectedItems: getSelectedItems(),
            vendor_id: vendorId,
          });
  
          const { clientId: newClientId } = response.data;
          setClientId(newClientId);
          await handleTransaction(newClientId, getSelectedItems());
        } catch (creationError) {
          console.error('Erreur lors de la création du client :', creationError);
          setError("Erreur lors de la création du client.");
        }
      } else {
        setError(error.response?.data?.message || 'Une erreur est survenue.');
      }
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleTransaction = async (clientId, selectedItems) => {
    if (!clientId || !vendorId) {
      setError('Données manquantes pour la transaction.');
      return;
    }
  
    try {
      const transactionResponse = await axiosInstance.post('/start-transaction', {
        clientId,
        vendor_id: vendorId,
      });
  
      const { transactionId, paymentUrl } = transactionResponse.data;
  
      if (transactionId && paymentUrl) {
        // Inclure clientId dans l'état lors de la navigation
        navigate('/vendor-panel/loading-payment', { state: { transactionId, paymentUrl, clientId } });
      } else {
        throw new Error('Transaction non créée correctement.');
      }
    } catch (error) {
      console.error('Erreur lors du traitement de la transaction :', error);
      setError('Erreur lors de la création de la transaction.');
    }
  };
  

  const ajouterClient = async (clientId, selectedItems) => {
    try {
      const response = await axiosInstance.post('/ajouter-client', {
        nom,
        prenom,
        pesel,
        email,
        telephone,
        adresse,
        ville,
        code_postal: codePostal,
        selectedItems,
        vendor_id: vendorId,
      });

      setClientId(response.data.clientId);
      setShowPopup(false);
      return response.data;
    } catch (error) {
      setError("Erreur lors de l'ajout ou la mise à jour du client.");
      throw error;
    }
  };

  const handleValidateChanges = async () => {
    setIsLoading(true);
    try {
      const selectedItems = getSelectedItems();
      const updatedClient = await ajouterClient(clientId, selectedItems);
      await handleTransaction(updatedClient.clientId, selectedItems);
    } catch (error) {
      setError('Erreur lors de la validation ou de la création de la transaction.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelChanges = () => {
    setShowPopup(false);
    setCurrentStep(6); // Retour à l'étape 6
  };

  return (
    <Container className="mt-4">
      <h2 className={`${styles.titleHeading} text-center mb-4`}>
        {t('Résumé des informations')}
      </h2>

      {error && <p className="text-danger text-center">{error}</p>}

      <Row className="g-4">
        {bikeType && (
          <Col md={4}>
            <Card className="shadow-sm">
              <Card.Header className="text-white text-center" style={{ backgroundColor: '#4eb933' }}>
                <h5><FaBicycle className="me-2" /> {t('Informations sur le vélo')}</h5>
              </Card.Header>
              <Card.Body>
                <p><strong>{t('Type')}:</strong> {bikeType}</p>
                <p><strong>{t('Marque')}:</strong> {marque}</p>
                <p><strong>{t('Modèle')}:</strong> {modele}</p>
                <p><strong>{t('Prix')}:</strong> {prixAchat} €</p>
                <p><strong>{t('Date d\'achat')}:</strong> {`${dateAchat.day}/${dateAchat.month}/${dateAchat.year}`}</p>
                <p><strong>{t('Propriété')}:</strong> {proprieteVelo}</p>
              </Card.Body>
            </Card>
          </Col>
        )}

        {selectedAssurance && (
          <Col md={4}>
            <Card className="shadow-sm">
              <Card.Header className="text-white text-center" style={{ backgroundColor: '#4eb933' }}>
                <h5><FaShieldAlt className="me-2" /> {t('Assurance sélectionnée')}</h5>
              </Card.Header>
              <Card.Body>
                <p><strong>{t('Nom')}:</strong> {selectedAssurance.nom}</p>
                <p><strong>{t('Prix')}:</strong> {selectedAssurance.prix} € / mois</p>
              </Card.Body>
            </Card>
          </Col>
        )}

        <Col md={4}>
          <Card className="shadow-sm">
            <Card.Header className="text-white text-center" style={{ backgroundColor: '#4eb933' }}>
              <h5><FaUser className="me-2" /> {t('Informations personnelles')}</h5>
            </Card.Header>
            <Card.Body>
              <p><strong>{t('Nom')}:</strong> {nom}</p>
              <p><strong>{t('Prénom')}:</strong> {prenom}</p>
              <p><strong>{t('Pesel')}:</strong> {pesel}</p>
              <p><strong>{t('Email')}:</strong> {email}</p>
              <p><strong>{t('Téléphone')}:</strong> {telephone}</p>
              <p><strong>{t('Adresse')}:</strong> {adresse}, {ville}, {codePostal}</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <div className="text-center mt-4">
        <Button
          style={{ backgroundColor: '#4eb933', border: 'none' }}
          className="px-5"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? <Spinner animation="border" size="sm" /> : t('Valider')}
        </Button>
      </div>

      {showPopup && (
        <Modal show={showPopup} onHide={() => setShowPopup(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Changements détectés')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul>
              {changes.map((change) => (
                <li key={change.field}>
                  <strong>{change.field}:</strong> {change.oldValue} → {change.newValue}
                </li>
              ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancelChanges}>
              {t('Annuler')}
            </Button>
            <Button style={{ backgroundColor: '#4eb933', border: 'none' }} onClick={handleValidateChanges}>
              {t('Confirmer')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default Recapitulatif;
