import React, { useEffect, useState } from 'react';
import VendorNavbar from '../VendorNavbar';
import { useNavigate, useParams } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import styles from './Assurances.module.css';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../../API/axiosInstance'; 

const Assurances = () => {
  const { t } = useTranslation();
  const [assurances, setAssurances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedAssurance, setSelectedAssurance] = useState(null);
  const [advantages, setAdvantages] = useState([]); // Avantages liés
  const [pdfData, setPdfData] = useState({ pdf_ipid: null, pdf_notice: null }); // Liens PDF
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAssurances = async () => {
      try {
        const vendorId = localStorage.getItem('vendorId');
        if (!vendorId) {
          throw new Error('Vendor ID manquant');
        }

        const response = await axiosInstance.get(`/sites/${vendorId}/assurances`);
        const uniqueAssurances = Array.from(new Set(response.data.map((a) => a.id))).map((id) =>
          response.data.find((a) => a.id === id)
        );
        setAssurances(uniqueAssurances);
      } catch (error) {
        console.error('Erreur lors de la récupération des assurances :', error);
        setError(t('ParcourClient.errors.fetch_assurances'));
      } finally {
        setLoading(false);
      }
    };

    fetchAssurances();
  }, [t]);

  const handleBack = () => {
    navigate('/vendor-panel');
  };

  const handleVoirPlus = async (assurance) => {
    setSelectedAssurance(assurance);

    try {
      const response = await axiosInstance.get(`/assurances/${assurance.id}`);
      setAdvantages(response.data.avantages || []);
      setPdfData({
        pdf_ipid: response.data.pdf_ipid,
        pdf_notice: response.data.pdf_notice,
      });
    } catch (error) {
      console.error('Erreur lors de la récupération des avantages :', error);
    }
  };

  const handleRetour = () => {
    setSelectedAssurance(null);
    setAdvantages([]);
    setPdfData({ pdf_ipid: null, pdf_notice: null });
  };

  const handlePdfView = (base64Data) => {
    if (!base64Data) {
      alert('Le document PDF est manquant.');
      return;
    }

    const binaryString = window.atob(base64Data);
    const bytes = Uint8Array.from(binaryString, (char) => char.charCodeAt(0));
    const blob = new Blob([bytes], { type: 'application/pdf' });
    return URL.createObjectURL(blob);
  };

  const renderAdvantages = () => (
    
    <div className={`card ${styles.advantagesContainer}`}>
      <div className={`card-header ${styles.cardHeader}`}>
        <h5>Les Avantages de l'assurance</h5>
      </div>
      <div className={`card-body ${styles.advantagesContent}`}>
        {/* Texte d'introduction */}
        <p className="text-muted mb-4">
          Découvrez les avantages associés à l'assurance que vous avez sélectionnée. Chaque avantage est conçu pour vous offrir une couverture optimale et une tranquillité d'esprit totale. Vous trouverez ci-dessous les détails complets de ces avantages.
        </p>
        <ul className={`${styles.advantagesList} list-unstyled`}>
          {advantages.map((advantage, index) => (
            <li key={index} className="mb-3">
              <div className="d-flex align-items-start">
                {/* Icône devant le titre */}
                <i className="bi bi-chevron-compact-right me-2 text-success" style={{ fontSize: '1.5rem' }}></i>
                <div>
                  <strong className="text-dark">{advantage.titre} :</strong>
                  <p className="text-muted mb-0">{advantage.description}</p>
                </div>
              </div>
              {/* Ligne subtile */}
              {index < advantages.length - 1 && (
                <hr className="mt-2 mb-2 text-muted" style={{ opacity: 0.5 }} />
              )}
            </li>
          ))}
        </ul>
        <div className="mt-3 d-flex justify-content-between align-items-center">
          {/* Liens des PDFs */}
          <div>
            {pdfData.pdf_ipid && (
              <a
                href={handlePdfView(pdfData.pdf_ipid)}
                target="_blank"
                rel="noopener noreferrer"
                className={`${styles.pdfLink}`}
                style={{ textDecoration: 'none', color: '#4eb933', fontWeight: 'bold' }}
              >
                📄 Fiche IPID
              </a>
            )}
            {pdfData.pdf_notice && (
              <a
                href={handlePdfView(pdfData.pdf_notice)}
                target="_blank"
                rel="noopener noreferrer"
                className={`${styles.pdfLink} ms-3`}
                style={{ textDecoration: 'none', color: '#4eb933', fontWeight: 'bold' }}
              >
                📑 Notice PDF
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
  

  const renderRows = () => (
    assurances.map((assurance) => (
      <div
        key={assurance.id}
        className={`card ${selectedAssurance?.id === assurance.id ? styles.selectedCard : styles.defaultCard}`}
      >
        <div className={`card-header ${styles.cardHeader}`}>
          <h5>{assurance.nom.toUpperCase()}</h5>
        </div>
        <div className={`card-body ${styles.assuranceContent}`}>
          <div className={styles.assuranceDetails}>
            <h6>
              <strong>{t('ParcourClient.assurance_content.value')}:</strong>{' '}
              <span className={styles.assuranceValue}>{assurance.prix} € / Mois</span>
            </h6>
            <p>
              <strong>{t('ParcourClient.assurance_content.description')}:</strong> {assurance.description}
            </p>
          </div>
          <table className={styles.guaranteesTable}>
            <thead>
              <tr>
                <th>{t('ParcourClient.assurance_content.guarantees')}</th>
                <th>{t('ParcourClient.assurance_content.status')}</th>
              </tr>
            </thead>
            <tbody>
              {[
                { label: t('ParcourClient.assurance_content.steal'), key: 'vol' },
                { label: t('ParcourClient.assurance_content.attemptsteal'), key: 'tentative_vol' },
                { label: t('ParcourClient.assurance_content.break'), key: 'dommages_materiels' },
                { label: t('ParcourClient.assurance_content.naturaldisasters'), key: 'catastrophes_naturelles' },
                { label: t('ParcourClient.assurance_content.technologikdisasters'), key: 'catastrophes_technologiques' },
              ].map((guarantee, index) => (
                <tr key={index}>
                  <td className={styles.alignGuarantee}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          {t('ParcourClient.franchise')}: {assurance[`${guarantee.key}_franchise`] || '0'}%
                        </Tooltip>
                      }
                    >
                      <span className={styles.infoIcon}>?</span>
                    </OverlayTrigger>
                    {guarantee.label}
                  </td>
                  <td className="text-center">{assurance[guarantee.key] ? '✔' : '✖'}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Bouton Voir les avantages uniquement si aucune assurance n'est sélectionnée */}
          {!selectedAssurance && (
            <div className="d-flex justify-content-center mt-4">
              <button
                className={`${styles.nextStepBtn}`}
                onClick={() => handleVoirPlus(assurance)}
              >
                Voir les avantages
              </button>
            </div>
          )}
        </div>
      </div>
    ))
  );
  return (
    <>
      <VendorNavbar hideSearchBar={true} showBackButton={true} stepBack={handleBack} />
      <div className={`${styles.contentContainer}`}>
        <div className="d-flex align-items-center justify-content-center position-relative mb-4">
          {/* Bouton Retour Assurances */}
          {selectedAssurance && (
            <button
              className={`btn btn-link d-flex align-items-center ${styles.backButton}`}
              onClick={handleRetour}
              style={{
                position: 'absolute',
                left: '0', // Force le bouton à être aligné à gauche
                textDecoration: 'none',
                color: '#4eb933', // Texte en vert
                fontWeight: 'bold',
                paddingLeft: '20px', // Ajoute un peu d'espace à gauche
              }}
            >
              <i className="bi bi-arrow-left me-2"></i> Retour assurances
            </button>
          )}
          {/* Titre Centré */}
          <h2 className={`${styles.titleHeading} mb-0`}>
            {t('ParcourClient.quesion7.selection_assurance')}
          </h2>
        </div>
  
        {loading ? (
          <div className="text-center">{t('ParcourClient.loading')}</div>
        ) : error ? (
          <div className="text-center text-danger">{error}</div>
        ) : assurances.length === 0 ? (
          <div className="text-center">{t('ParcourClient.no_assurances_available')}</div>
        ) : selectedAssurance ? (
          <div className="row g-3">
            {/* Carte Assurance - 1/3 */}
            <div className="col-lg-4 col-md-12">
              {renderRows().find((card) => card.key === selectedAssurance.id.toString())}
            </div>
            {/* Carte Avantages - 2/3 */}
            <div className="col-lg-8 col-md-12">{renderAdvantages()}</div>
          </div>
        ) : (
          <div className={`${styles.cardsContainer}`}>{renderRows()}</div>
        )}
      </div>
    </>
  );
  
};

export default Assurances;
