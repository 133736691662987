import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const location = useLocation();

  const menuItems = [
    { path: '/superadmin-panel', icon: 'bi bi-grid-1x2-fill', label: 'Dashboard' },
    { path: '/superadmin-panel/manage-company', icon: 'bi bi-building', label: 'Entreprise (clients)' },
    { path: '/superadmin-panel/manage-assureur', icon: 'bi bi-award', label: 'Assureurs' },
    { path: '/superadmin-panel/manage-assurance', icon: 'bi bi-card-heading', label: 'Assurances' },
    { path: '/superadmin-panel/manage-users', icon: 'bi bi-people', label: 'Utilisateur' },
    { path: '/superadmin-panel/add-company', icon: 'bi bi-journal-plus', label: 'Ajouter entreprise' },
    { path: '/superadmin-panel/add-assureur', icon: 'bi bi-bank', label: 'Ajouter Assureur' },
    { path: '/superadmin-panel/add-assurance', icon: 'bi bi-plus-circle-dotted', label: 'Ajouter une assurance' },
    { path: '/superadmin-panel/add-user', icon: 'bi bi-person-plus', label: 'Ajouter un utilisateur' },
    { path: '/clients', icon: 'bi bi-person-arms-up', label: 'Visualiser clients' },
    { path: '/transactions', icon: 'bi bi-currency-exchange', label: 'Visualiser transactions' },
  ];

  return (
    <ul
      className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
      id="accordionSidebar"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '250px', // Assurez-vous que la largeur est cohérente
        zIndex: 1050, // Z-index supérieur pour passer au-dessus de la navbar
        overflowY: 'auto', // Permet le défilement si la sidebar dépasse l'écran
      }}
    >
      {/* Sidebar Brand */}
      <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
        <div className="sidebar-brand-text mx-3">Super Admin Panel</div>
      </a>

      <hr className="sidebar-divider my-0" />

      {/* Dashboard Section */}
      {menuItems.slice(0, 1).map((item) => (
        <li
          key={item.path}
          className={`nav-item ${location.pathname === item.path ? 'active bg-primary' : ''} py-1`}
        >
          <Link
            className={`nav-link ${location.pathname === item.path ? 'text-white' : 'text-light'} py-1`}
            to={item.path}
          >
            <i className={item.icon}></i>
            <span>{item.label}</span>
          </Link>
        </li>
      ))}

      <div className="sidebar-heading my-0">Utilitaire</div>

      {/* Utility Section */}
      {menuItems.slice(1, 5).map((item) => (
        <li
          key={item.path}
          className={`nav-item ${location.pathname === item.path ? 'active bg-primary' : ''} py-1`}
        >
          <Link
            className={`nav-link ${location.pathname === item.path ? 'text-white' : 'text-light'} py-1`}
            to={item.path}
          >
            <i className={item.icon}></i>
            <span>{item.label}</span>
          </Link>
        </li>
      ))}

      <hr className="sidebar-divider my-0" />
      <div className="sidebar-heading my-0">Actions</div>

      {/* Actions Section */}
      {menuItems.slice(5,9).map((item) => (
        <li
          key={item.path}
          className={`nav-item ${location.pathname === item.path ? 'active bg-primary' : ''} py-1`}
        >
          <Link
            className={`nav-link ${location.pathname === item.path ? 'text-white' : 'text-light'} py-1`}
            to={item.path}
          >
            <i className={item.icon}></i>
            <span>{item.label}</span>
          </Link>
        </li>
      ))}

      <hr className="sidebar-divider d-none d-md-block my-0" />
      <hr className="sidebar-divider my-0" />
      <div className="sidebar-heading my-0">Clients</div>

      {/* Actions Section */}
      {menuItems.slice(9).map((item) => (
        <li
          key={item.path}
          className={`nav-item ${location.pathname === item.path ? 'active bg-primary' : ''} py-1`}
        >
          <Link
            className={`nav-link ${location.pathname === item.path ? 'text-white' : 'text-light'} py-1`}
            to={item.path}
          >
            <i className={item.icon}></i>
            <span>{item.label}</span>
          </Link>
        </li>
      ))}

      <hr className="sidebar-divider d-none d-md-block my-0" />
    </ul>

    
  );
};

export default Sidebar;
