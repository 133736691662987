import React, { useState, useEffect } from 'react';
import styles from './ParcoursClient.module.css';
import popupStyles from './Popup.module.css'; // Import des styles pour la popup
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../../API/axiosInstance'; // Import de l'instance axios pour les requêtes API

const ClientInfo = ({ formData, setFormData, setCurrentStep }) => {
  const { t } = useTranslation();
  const [nom, setNom] = useState(formData.nom || '');
  const [prenom, setPrenom] = useState(formData.prenom || '');
  const [email, setEmail] = useState(formData.email || '');
  const [pesel, setPesel] = useState(formData.pesel || '');
  const [telephone, setTelephone] = useState(formData.telephone || '');
  const [adresse, setAdresse] = useState(formData.adresse || '');
  const [ville, setVille] = useState(formData.ville || '');
  const [codePostal, setCodePostal] = useState(formData.codePostal || '');

  const [popupMessage, setPopupMessage] = useState(null); // Message d'erreur ou de succès
  const [userDetails, setUserDetails] = useState(null); // Détails de l'utilisateur si trouvé

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      nom,
      prenom,
      email,
      pesel,
      telephone,
      adresse,
      ville,
      codePostal,
    }));
  }, [nom, prenom, pesel, email, telephone, adresse, ville, codePostal, setFormData]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleAlreadyClient = async () => {
      if (!email || !validateEmail(email)) {
          setPopupMessage('Veuillez entrer un email valide.');
          return;
      }

      try {
          const response = await axiosInstance.get('/client/get-client-by-email', {
              params: { email },
          });

          if (response.status === 200 && response.data.user) {
              setUserDetails(response.data.user); // Utilisateur trouvé
              setPopupMessage(null); // Supprimer tout message d'erreur précédent
          }
      } catch (error) {
          if (error.response && error.response.status === 404) {
              // L'email n'existe pas, afficher un message clair
              setPopupMessage("Ce compte n'est pas encore créé.");
          } else {
              // Toute autre erreur (connexion, serveur, etc.)
              setPopupMessage('Erreur de connexion au serveur. Veuillez réessayer plus tard.');
              console.error('Erreur lors de la vérification de l\'email :', error);
          }
      }
  };

  const handleClosePopup = () => {
    setPopupMessage(null);
    setUserDetails(null);
  };

  const handleValidatePopup = () => {
    if (userDetails) {
      setNom(userDetails.first_name || '');
      setPrenom(userDetails.last_name || '');
      setPesel(userDetails.pesel || '');
      setTelephone(userDetails.telephone || '');
      setAdresse(userDetails.adresse || '');
      setVille(userDetails.ville || '');
      setCodePostal(userDetails.code_postal || '');
      handleClosePopup();
    }
  };

  const handleContinue = () => {
    if (nom && prenom && pesel && email && telephone && adresse && ville && codePostal) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  return (
    <div className={`${styles.container} ${styles.my4}`}>
      <h2 className={`${styles.titleHeading} text-center mb-4`}>
        {t('ParcourClient.questions6.info_custommer')}
      </h2>

      {/* Champ email avec bouton "Déjà client" */}
      <div
        className={`${styles.formGroup}`}
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '10px',
          width: '100%',
          maxWidth: '450px',
          margin: '0 auto',
        }}
      >
        <div style={{ flex: 1 }}>
          <label
            htmlFor="email"
            style={{
              display: 'block',
              marginBottom: '5px',
              fontSize: '14px',
            }}
          >
            {t('ParcourClient.option_info_custommer.email')}
          </label>
          <input
            type="email"
            id="email"
            className={`${styles.formControl} ${styles.selectStyle}`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <button
          className={`${styles.alreadyClientBtn}`}
          onClick={handleAlreadyClient}
          style={{
            marginTop: '25px',
          }}
        >
          Déjà client ?
        </button>
      </div>

      {/* Autres champs */}
      <div className={`${styles.formGroup}`} style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <label htmlFor="nom">{t('ParcourClient.option_info_custommer.name')}</label>
        <input
          type="text"
          id="nom"
          className={`${styles.formControl} ${styles.selectStyle}`}
          value={nom}
          onChange={(e) => setNom(e.target.value)}
        />
      </div>
      <div className={`${styles.formGroup}`} style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <label htmlFor="prenom">{t('ParcourClient.option_info_custommer.surname')}</label>
        <input
          type="text"
          id="prenom"
          className={`${styles.formControl} ${styles.selectStyle}`}
          value={prenom}
          onChange={(e) => setPrenom(e.target.value)}
        />
      </div>
      <div className={`${styles.formGroup}`} style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <label htmlFor="pesel">{t('ParcourClient.option_info_custommer.pesel')}</label>
        <input
          type="text"
          id="pesel"
          className={`${styles.formControl} ${styles.selectStyle}`}
          value={pesel}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]/g, ''); 
            if (value.length <= 11) {
              setPesel(value); 
            }
          }}
          maxLength="11" 
        />
      </div>
      <div className={`${styles.formGroup}`} style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <label htmlFor="telephone">{t('ParcourClient.option_info_custommer.smartphone')}</label>
        <input
          type="tel"
          id="telephone"
          className={`${styles.formControl} ${styles.selectStyle}`}
          value={telephone}
          onChange={(e) => setTelephone(e.target.value)}
        />
      </div>
      <div className={`${styles.formGroup}`} style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <label htmlFor="adresse">{t('ParcourClient.option_info_custommer.adress')}</label>
        <input
          type="text"
          id="adresse"
          className={`${styles.formControl} ${styles.selectStyle}`}
          value={adresse}
          onChange={(e) => setAdresse(e.target.value)}
        />
      </div>
      <div className={`${styles.formGroup}`} style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <label htmlFor="ville">{t('ParcourClient.option_info_custommer.city')}</label>
        <input
          type="text"
          id="ville"
          className={`${styles.formControl} ${styles.selectStyle}`}
          value={ville}
          onChange={(e) => setVille(e.target.value)}
        />
      </div>
      <div className={`${styles.formGroup}`} style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
        <label htmlFor="codePostal">{t('ParcourClient.option_info_custommer.postalcode')}</label>
        <input
          type="text"
          id="codePostal"
          className={`${styles.formControl} ${styles.selectStyle}`}
          value={codePostal}
          onChange={(e) => setCodePostal(e.target.value)}
        />
      </div>

      {/* Popup */}
      {popupMessage && (
        <div className={popupStyles.popup}>
          <div className={popupStyles.popupContent}>
            <p>{popupMessage}</p>
            <button onClick={handleClosePopup} className={popupStyles.closeBtn}>
              Fermer
            </button>
          </div>
        </div>
      )}

      {userDetails && (
        <div className={popupStyles.popup}>
          <div className={popupStyles.popupContent}>
            <h3 className={popupStyles.popupTitle}>Informations du client</h3>
            <ul>
              <li><strong>Nom :</strong> {userDetails.first_name}</li>
              <li><strong>Prénom :</strong> {userDetails.last_name}</li>
              <li><strong>Pesel :</strong> {userDetails.pesel}</li>
              <li><strong>Email :</strong> {userDetails.email}</li>
              <li><strong>Téléphone :</strong> {userDetails.telephone}</li>
              <li><strong>Adresse :</strong> {userDetails.adresse}</li>
              <li><strong>Ville :</strong> {userDetails.ville}</li>
              <li><strong>Code Postal :</strong> {userDetails.code_postal}</li>
            </ul>
            <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
              <button onClick={handleValidatePopup} className={popupStyles.validateBtn}>
                Valider
              </button>
              <button onClick={handleClosePopup} className={popupStyles.closeBtn}>
                Fermer
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Bouton "Continuer" */}
      {nom && prenom && pesel && email && telephone && adresse && ville && codePostal && (
        <div className="text-center mt-4">
          <button className={styles.nextStepBtn} onClick={handleContinue}>
            {t('Button.continue')}
          </button>
        </div>
      )}
    </div>
  );
};

export default ClientInfo;
