import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import VendorNavbar from '../VendorNavbar';
import ProgressBar from './Progress/ProgressBar';
import BikeSelection from './BikeSelection';
import DeplacementType from './DeplacementType';
import ProprieteVelo from './ProprieteVelo';
import DateAchat from './DateAchat';
import InfoVelo from './InfoVelo';
import ClientInfo from './ClientInfo';
import Recapitulatif from './Recapitulatif';
import LoadingPayement from '../Load/LoadingPayment';
import SelectionAssurance from './SelectionAssurance';
import styles from './ParcoursClient.module.css';

const ParcoursClient = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    bikeType: '',
    deplacementType: '',
    siteId: '',
  });
  const [vendorId, setVendorId] = useState(null);

  // Charger les données transmises par navigation (étape et formData)
  useEffect(() => {
    const state = location.state;
    if (state?.step) setCurrentStep(state.step);
    if (state?.formData) setFormData(state.formData);
  }, [location.state]);

  // Charger l'ID du vendeur depuis le token
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setVendorId(decodedToken.id);
      } catch (error) {
        console.error('Erreur lors du décodage du token', error);
      }
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const steps = [
    <BikeSelection formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />,
    <DeplacementType formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />,
    <DateAchat formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />,
    <ProprieteVelo formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />,
    <InfoVelo formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />,
    <ClientInfo formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />,
    <SelectionAssurance formData={formData} setFormData={setFormData} setCurrentStep={setCurrentStep} />,
    <Recapitulatif formData={formData} vendorId={vendorId} setCurrentStep={setCurrentStep} />,
    formData.transactionId ? (
      <LoadingPayement transactionId={formData.transactionId} clientId={formData.clientId} formData={formData} />
    ) : null,
  ];

  const handleStepBack = () => {
    if (currentStep === 1) {
      navigate('/vendor-panel');
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <>
      <VendorNavbar hideSearchBar={true} showBackButton={true} stepBack={handleStepBack} />
      <ProgressBar currentStep={currentStep} totalSteps={steps.length - 1} />
      <div className={styles.container}>
        <div className={styles.contentContainer}>{steps[currentStep - 1]}</div>
      </div>
    </>
  );
};

export default ParcoursClient;
